import { useState } from "react"
import { FaPlus, FaMinus } from "react-icons/fa6";

const minusIcon = <FaMinus/>
const plusIcon = <FaPlus/>

const Accordion = ({ title, content }) => {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded((current) => !current)

    return (
      <div className="border-b border-slate-200" onClick={toggleExpanded}>
        <div className=" text-left items-center h-16 select-none flex justify-between flex-row">
          <span>{title}</span>
          <div className="text-slate-800 transition-transform duration-300">{expanded ? minusIcon : plusIcon}</div>
        </div>
        <div className={`pt-0 overflow-hidden transition-[max-height] duration-500 ease-in ${expanded ? "max-h-40" : "max-h-0"}`}>
          <ul className="pb-4 text-left list-disc list-inside">
            {content.map((item, key) => (
                <li key={key}>{item}</li>
            ))}
            {/* {content} */}
          </ul>
        </div>
      </div>
    )
  }

  export default Accordion;