export const navbar = [
    {
        id: 0,
        menu: "Our Brand",
        total: 1,
        list: [{
            id: 0,
            category: "OUR BRANDS",
            menuList: [
                {
                    name: "PIERRE UNO",
                    link: ""
                },
                {
                    name: "JORDAN CAVALLI",
                    link: ""
                },
                {
                    name: "BEYONDSKIN",
                    link: ""
                },
                {
                    name: "RUDES & SON",
                    link: ""
                },
                {
                    name: "ARROW APPLE",
                    link: ""
                },
                {
                    name: "CATASY",
                    link: ""
                },
                {
                    name: "PIERRE UNO KIDS",
                    link: ""
                },
                {
                    name: "FINY GIRLS",
                    link: ""
                },
            ]
        }]
    },
    {
        id: 0,
        menu: "MEN",
        total: 4,
        list: [
            {
                id: 0,
                category: "PIERRE UNO",
                menuList: [
                    {
                        name: "PIERRE UNO",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "JORDAN CAVALLI",
                menuList: [
                    {
                        name: "JORDAN CAVALLI",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 2,
                category: "BEYONDSKIN",
                menuList: [
                    {
                        name: "BEYONDSKIN",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    }
                ]
            },
            {
                id: 3,
                category: "RUDES & SON",
                menuList: [
                    {
                        name: "RUDES & SON",
                        link: ""
                    },
                    {
                        name: "ALL PRODUCT",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Shirts/Tops",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                    {
                        name: "Sleep/Lounge",
                        link: ""
                    },
                ]
            }
        ]
    },
    {
        id: 0,
        menu: "MEN",
        total: 2,
        list: [
            {
                id: 0,
                category: "ARROW APPLE",
                menuList: [
                    {
                        name: "ARROW APPLE",
                        link: ""
                    },
                    {
                        name: "All Products",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Underwear",
                        link: ""
                    },
                ]
            },
            {
                id: 1,
                category: "STYL",
                menuList: [
                    {
                        name: "STYL ",
                        link: ""
                    },
                    {
                        name: "All Products ",
                        link: ""
                    },
                    {
                        name: "New Arrivals",
                        link: ""
                    },
                    {
                        name: "Best Sellers",
                        link: ""
                    },
                    {
                        name: "Pajamas",
                        link: ""
                    },
                    {
                        name: "Nightgowns",
                        link: ""
                    }
                ]
            }
        ]
    }
]

export const DataProducts = [
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "/img/product/1.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/2.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/3.png"
    },
    {
        productName: "laron",
        productPrice: "Rp 45000",
        productSize: "XL",
        ProductColor: "blue",
        productImage: "../img/product/4.png"
    }
]

export const DataProduct = [
    {
        id: 0,
        category: "All",
        slug: "all-mens",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productTag: "Jordan Cavalli",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "/img/jc_boy/1.png",
                    "/img/jc_boy/2.png",
                    "/img/jc_boy/3.png",
                    "/img/jc_boy/4.png",
                    "/img/jc_boy/5.png",
                    "/img/jc_boy/6.png",
                    "/img/jc_boy/7.png",
                    "/img/jc_boy/8.png",
                    "/img/jc_boy/9.png",
                    "/img/jc_boy/10.png",
                ],
                productThumbnail: "/img/jc_boy/1.png"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "/img/PU/PU-BOXER1/BX1.png",
                    "/img/PU/PU-BOXER1/BX2.png",
                    "/img/PU/PU-BOXER1/BX3.png",
                    "/img/PU/PU-BOXER1/BX4.png",
                    "/img/PU/PU-BOXER1/BX5.png",
                    "/img/PU/PU-BOXER1/BX6.png",
                ],
                productThumbnail: "/img/PU/PU-BOXER1/BX1.png"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/PU/PU-BOXER2/BX1.png",
                    "../img/PU/PU-BOXER2/BX2.png",
                    "../img/PU/PU-BOXER2/BX3.png",
                    "../img/PU/PU-BOXER2/BX4.png",
                    "../img/PU/PU-BOXER2/BX5.png",
                    "../img/PU/PU-BOXER2/BX6.png",
                ],
                productThumbnail: "../img/PU/PU-BOXER2/BX6.png"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/PU/PU-B01M/IMG_0021.jpg",
                    "../img/PU/PU-B01M/IMG_0026.jpg",
                    "../img/PU/PU-B01M/IMG_0030.jpg",
                    "../img/PU/PU-B01M/IMG_0032.jpg",
                ],
                productThumbnail: "../img/PU/PU-B01M/IMG_0021.jpg"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img//PU/PU-B05M/IMG_0089.png",
                    "../img//PU/PU-B05M/IMG_0092.png",
                    "../img//PU/PU-B05M/IMG_0096.png",
                    "../img//PU/PU-B05M/IMG_0097.png",
                    "../img//PU/PU-B05M/IMG_0098.png",
                    "../img//PU/PU-B05M/IMG_0099.png",
                ],
                productThumbnail: "../img//PU/PU-B05M/IMG_0089.png"
            },
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/p+1/PO-BS01 M_001.png",
                    "../img/p+1/PO-BS01 M_002.png",
                    "../img/p+1/PO-BS01 M_003.png",
                    "../img/p+1/PO-BS01 M_006.png",
                    "../img/p+1/PO-BS01 M_007.png",
                    "../img/p+1/PO-BS01 M_008.png",
                ],
                productThumbnail: "../img/p+1/PO-BS01 M_001.png"
            }
        ]
    },
    // {
    //     id: 0,
    //     category: "All",
    //     slug: "all-women",
    //     type: "WOMEN",
    //     listProduct: [
    //         {
    //             productName: "AA-B01 F (Rio-Best Seller)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.png"
    //         },
    //         {
    //             productName: "AA-B02 F (Alfa)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0003.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0011.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0012.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0019.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0021.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0027.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.png"
    //         }, 
    //         {
    //             productName: "AA-B04 F (Cotton)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0157.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0160.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0162.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0165.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0166.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0168.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.png"
    //         }
    //     ]
    // },
    // {
    //     id: 0,
    //     category: "All",
    //     slug: "all-teens",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "AA-S01",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/AA/AA-S01 BM_001.png",
    //                 "../img/teens/AA/AA-S01 BM_002.png",
    //                 "../img/teens/AA/AA-S01 BM_003.png",
    //                 "../img/teens/AA/AA-S01 BM_004.png",
    //                 "../img/teens/AA/AA-S01 BM_005.png",
    //             ],
    //             productThumbnail: "../img/teens/AA/AA-S01 BM_001.png"
    //         },
    //         {
    //             productName: "JC-BR01 T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.png",
    //                 "../img/teens/JC-BR01 T/IMG_0495.png",
    //                 "../img/teens/JC-BR01 T/IMG_0501.png",
    //             ],
    //             productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",

    //         }, 
    //         {
    //             productName: "PU-BR02T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BR02T/IMG_0037.png",
    //                 "../img/teens/PU-BR02T/IMG_0038.png",
    //                 "../img/teens/PU-BR02T/IMG_0040.png",
    //                 "../img/teens/PU-BR02T/IMG_0044.png",
    //                 "../img/teens/PU-BR02T/IMG_0047.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BR02T/IMG_0037.png",
    //         }, 
    //         {
    //             productName: "PU-BS01T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS01T/IMG_0104.png",
    //                 "../img/teens/PU-BS01T/IMG_0106.png",
    //                 "../img/teens/PU-BS01T/IMG_0108.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS01T/IMG_0104.png",
    //         }, 
    //         {
    //             productName: "PU-BS09T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS09T/IMG_0235.png",
    //                 "../img/teens/PU-BS09T/IMG_0239.png",
    //                 "../img/teens/PU-BS09T/IMG_0246.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS09T/IMG_0235.png",
    //         }, 
    //     ]
    // },
    {
        id: 0,
        category: "All",
        slug: "all-kids",
        type: "KIDS",
        listProduct: [
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / NECI",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / KARTUN HEWAN",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD DREAM",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD Kimono",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / GIRL ONLY",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD LEAP BEFORE SLEEP",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FI-B18 G (Little Girl)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
            },
            {
                productName: "PIERRE UNO 3 PCS CELANA DALAM ANAK LAKI-LAKI / CD Dino",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
            },  
        ]
    },
    {
        id: 0,
        category: "Jordan Cavalli",
        slug: "jordan-cavalli",
        type: "MENS",
        listProduct: [
            {
                productName: "JC Boy",
                productTag: "Jordan Cavalli",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "/img/jc_boy/1.png",
                    "/img/jc_boy/2.png",
                    "/img/jc_boy/3.png",
                    "/img/jc_boy/4.png",
                    "/img/jc_boy/5.png",
                    "/img/jc_boy/6.png",
                    "/img/jc_boy/7.png",
                    "/img/jc_boy/8.png",
                    "/img/jc_boy/9.png",
                    "/img/jc_boy/10.png",
                ],
                productThumbnail: "/img/jc_boy/1.png"
            },
        ]
    },
    {
        id: 1,
        category: "Pierre Uno",
        slug: "pierre-uno",
        type: "MENS",
        listProduct: [
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "/img/PU/PU-BOXER1/BX1.png",
                    "/img/PU/PU-BOXER1/BX2.png",
                    "/img/PU/PU-BOXER1/BX3.png",
                    "/img/PU/PU-BOXER1/BX4.png",
                    "/img/PU/PU-BOXER1/BX5.png",
                    "/img/PU/PU-BOXER1/BX6.png",
                ],
                productThumbnail: "/img/PU/PU-BOXER1/BX1.png"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/PU/PU-BOXER2/BX1.png",
                    "../img/PU/PU-BOXER2/BX2.png",
                    "../img/PU/PU-BOXER2/BX3.png",
                    "../img/PU/PU-BOXER2/BX4.png",
                    "../img/PU/PU-BOXER2/BX5.png",
                    "../img/PU/PU-BOXER2/BX6.png",
                ],
                productThumbnail: "../img/PU/PU-BOXER2/BX6.png"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/PU/PU-B01M/IMG_0021.jpg",
                    "../img/PU/PU-B01M/IMG_0026.jpg",
                    "../img/PU/PU-B01M/IMG_0030.jpg",
                    "../img/PU/PU-B01M/IMG_0032.jpg",
                ],
                productThumbnail: "../img/PU/PU-B01M/IMG_0021.jpg"
            },
            {
                productName: "Pierre Uno",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img//PU/PU-B05M/IMG_0089.png",
                    "../img//PU/PU-B05M/IMG_0092.png",
                    "../img//PU/PU-B05M/IMG_0096.png",
                    "../img//PU/PU-B05M/IMG_0097.png",
                    "../img//PU/PU-B05M/IMG_0098.png",
                    "../img//PU/PU-B05M/IMG_0099.png",
                ],
                productThumbnail: "../img//PU/PU-B05M/IMG_0089.png"
            },
        ]
    },
    {
        id: 2,
        category: "P+1",
        slug: "p+1",
        type: "MENS",
        listProduct: [
            {
                productName: "P+1",
                productTag: "P+1",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/p+1/PO-BS01 M_001.png",
                    "../img/p+1/PO-BS01 M_002.png",
                    "../img/p+1/PO-BS01 M_003.png",
                    "../img/p+1/PO-BS01 M_006.png",
                    "../img/p+1/PO-BS01 M_007.png",
                    "../img/p+1/PO-BS01 M_008.png",
                ],
                productThumbnail: "../img/p+1/PO-BS01 M_001.png"
            }
        ]
    },
    // {
    //     id: 3,
    //     category: "Arrow Apple",
    //     slug: "arrow-apple",
    //     type: "WOMEN",
    //     listProduct: [
    //         {
    //             productName: "AA-B01 F (Rio-Best Seller)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0045.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0047.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0051.png",
    //                 "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0053.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B01 F (Rio-Best Seller)/img_0041.png"
    //         },
    //         {
    //             productName: "AA-B02 F (Alfa)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0003.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0011.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0012.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0019.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0021.png",
    //                 "../img/women/AA/AA-B02 F (Alfa)/img_0027.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B02 F (Alfa)/img_0003.png"
    //         }, 
    //         {
    //             productName: "AA-B04 F (Cotton)",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0157.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0160.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0162.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0165.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0166.png",
    //                 "../img/women/AA/AA-B04 F (Cotton)/img_0168.png",
    //             ],
    //             productThumbnail: "../img/women/AA/AA-B04 F (Cotton)/img_0157.png"
    //         }
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Arrow Apple",
    //     slug: "teens-arrow-apple",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "AA-S01",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/AA/AA-S01 BM_001.png",
    //                 "../img/teens/AA/AA-S01 BM_002.png",
    //                 "../img/teens/AA/AA-S01 BM_003.png",
    //                 "../img/teens/AA/AA-S01 BM_004.png",
    //                 "../img/teens/AA/AA-S01 BM_005.png",
    //             ],
    //             productThumbnail: "../img/teens/AA/AA-S01 BM_001.png"
    //         },
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Jordan Cavalli Teens",
    //     slug: "teens-jordan-cavalli",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "JC-BR01 T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",
    //                 "../img/teens/JC-BR01 T/20230909-F JIO(1)  IMG_0498.png",
    //                 "../img/teens/JC-BR01 T/IMG_0495.png",
    //                 "../img/teens/JC-BR01 T/IMG_0501.png",
    //             ],
    //             productThumbnail: "../img/teens/JC-BR01 T/20230909-F JIO (1) IMG_0505.png",

    //         }, 
    //     ]
    // },
    // {
    //     id: 3,
    //     category: "Pierre Uno Teens",
    //     slug: "teens-pierre-uno",
    //     type: "TEENS",
    //     listProduct: [
    //         {
    //             productName: "PU-BR02T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BR02T/IMG_0037.png",
    //                 "../img/teens/PU-BR02T/IMG_0038.png",
    //                 "../img/teens/PU-BR02T/IMG_0040.png",
    //                 "../img/teens/PU-BR02T/IMG_0044.png",
    //                 "../img/teens/PU-BR02T/IMG_0047.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BR02T/IMG_0037.png",
    //         }, 
    //         {
    //             productName: "PU-BS01T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS01T/IMG_0104.png",
    //                 "../img/teens/PU-BS01T/IMG_0106.png",
    //                 "../img/teens/PU-BS01T/IMG_0108.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS01T/IMG_0104.png",
    //         }, 
    //         {
    //             productName: "PU-BS09T",
    //             productPrice: "Rp 45.000",
    //             productSize: ["S", "M", "L", "XL"],
    //             ProductColor: ["Yellow", "Green"],
    //             productImageList: [
    //                 "../img/teens/PU-BS09T/IMG_0235.png",
    //                 "../img/teens/PU-BS09T/IMG_0239.png",
    //                 "../img/teens/PU-BS09T/IMG_0246.png",
    //             ],
    //             productThumbnail: "../img/teens/PU-BS09T/IMG_0235.png",
    //         }, 
    //     ]
    // },
    {
        id: 3,
        category: "Pierre Uno Kid's",
        slug: "kids-pierre-uno",
        type: "KIDS",
        listProduct: [
            {
                productName: "PIERRE UNO 3 PCS CELANA DALAM ANAK LAKI-LAKI / CD Dino",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0146.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0152.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0202.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0204.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0207.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0209.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B02 B (CD Dino)/IMG_0142.jpg",
            }, 
            {
                productName: "PU-B04 B (CD Safari)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0176.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0177.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0178.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0180.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0184.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0187.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0190.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0192.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B04 B (CD Safari)/IMG_0172.jpg",
            }, 
            {
                productName: "PU-B05 B (CD Car (Best Seller)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0066.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0074.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0082.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0186.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0191.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0197.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B05 B (CD Car (Best Seller)/IMG_0061.jpg",
            }, 
            {
                productName: "PU-B06 B (CD Polos)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0122.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0125.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0127.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0133.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0254.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0255.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0261.jpg",
                    "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0262.jpg",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B06 B (CD Polos)/IMG_0120.jpg",
            },
            {
                productName: "PU-B08 B (CD Alien)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0094.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0098.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0101.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0106.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0244.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0252.png",
                    "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0253.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B08 B (CD Alien)/IMG_0091.png",
            }, 
            {
                productName: "PU-B10 B (CD Builder)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0216.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0223.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0227.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0229.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0234.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B10 B (CD Builder)/IMG_0166.png",
            }, 
            {
                productName: "PU-B13 B (CD Pirates)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0008.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0013.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0017.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0018.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0023.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0231.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0235.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0237.png",
                    "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0241.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-B13 B (CD Pirates)/IMG_0006.png",
            }, 
            {
                productName: "PU-BR12 B (CD Team Cat)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0142.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0149.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0155.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0159.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0169.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0201.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0205.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0211.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0214.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR12 B (CD Team Cat)/IMG_0139.png",
            }, 
            {
                productName: "PU-BR14 B (CD Space Craft)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0034.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0036.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0045.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0049.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0218.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0219.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0225.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0226.png",
                    "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0228.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-BR14 B (CD Space Craft)/IMG_0033.png",
            },
            {
                productName: "PU-KIDS 1X1 +WM",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_02.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_03.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_04.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_05.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_06.png",
                    "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL_07.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-KIDS 1X1 +WM/LIPAT ALL.png",
            },
            {
                productName: "PU-S01 B (Boy_s Singlet)",
                productTag: "Pierre Uno",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0193.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0195.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0221.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0224.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0240.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0243.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0245.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0265.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0277.png",
                    "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0282.png",
                ],
                productThumbnail: "../img/Kids (Boys) - PU Kids/PU-S01 B (Boy_s Singlet)/IMG_0190.png",
            },      
        ]
    },
    {
        id: 3,
        category: "Finy Girls Kid's",
        slug: "kids-finy-girls",
        type: "KIDS",
        listProduct: [
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / NECI",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0044.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0279.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0281.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0283.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0286.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0288.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0294.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B02 G (CD. Neci)/IMG_0035.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / KARTUN HEWAN",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0096.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0098.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0333.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0355.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0358.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0360.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0361.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0363.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0369.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B03 G (CD Animal Cartoon)/IMG_0088.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD DREAM",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0338.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0428.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0430.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0431.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0436.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B04 G (CD Dream)/IMG_0435.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD Kimono",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0260.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0264.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0270.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0272.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B15 G (CD Kimono)/IMG_0064.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / GIRL ONLY",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0113.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0344.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0347.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0349.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0352.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0353.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B16 G (Girl Only)/IMG_0102.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FINY GIRLS 3 PCS CELANA DALAM ANAK PEREMPUAN / CD LEAP BEFORE SLEEP",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0137.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0404.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0406.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0408.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0413.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0414.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B17 G (CD Leap Before Sleep)/IMG_0133.jpg",
            },  
            {
                productTag: "Finy Girls",
                productName: "FI-B18 G (Little Girl)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0085.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0086.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0371.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0373.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0375.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0378.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0381.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B18 G (Little Girl)/IMG_0077.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B20 G (CD Animal Face 01)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0299.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0303.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0312.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B20 G (CD Animal Face 01)/IMG_0047.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-B21 G (CD Femmiephant)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0009.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0016.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0313.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0317.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0318.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0325.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B21 G (CD Femmiephant)/IMG_0003.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-B26 G (CD Bunny (Best Seller)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0390.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0393.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0394.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0397.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0399.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0401.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-B26 G (CD Bunny (Best Seller)/IMG_0385.jpg",
            }, 
            {
                productTag: "Finy Girls",
                productName: "FI-BP11 G (CD Bow Fuschia)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0336.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0417.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0418.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0423.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0425.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0426.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BP11 G (CD Bow Fuschia)/IMG_0070.jpg",
            },   
            {
                productTag: "Finy Girls",
                productName: "FI-BR25 G (CD Animal Face 02)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0249.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0251.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0254.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0258.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-BR25 G (CD Animal Face 02)/IMG_0020.jpg",
            },
            {
                productTag: "Finy Girls",
                productName: "FI-S01 G (Girl Singlet)",
                productPrice: "IDR. 53,500",
                productDiscount: "IDR. 89.000",
                productSize: ["S/2-3Y", "M/4-5Y", "L/6-7Y", "XL/8-9Y", "3L/10-11Y", "4L/12-13Y"],
                productColor: ["Yellow", "Green"],
                productDescription: ["Bahan modal, lembut dan nyaman ", "Design menarik", "3 pcs/box, dengan variasi berbeda", "Jahitan dan pengerjaan yang rapi", "Lulus SNI, lebih aman untuk anak" ],
                productFabric: ["100% Katun", "Pelapis dalam: 100% Cotton"],
                productCare: ["Cuci dengan tangan atau mesin cuci dengan air hangat (30°C).", "Jika menggunakan mesin, gunakan siklus paling lembut yang tersedia.", "Jangan gunakan pemutih.", "Jangan dikeringkan dengan mesin pengering."], 
                productImageList: [
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0131.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0144.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0262.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0265.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0269.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0272.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0274.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0280.jpg",
                    "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0286.jpg",
                ],
                productThumbnail: "../img/Kids (Girls) - Finy Girls/FI-S01 G (Girl Singlet)/IMG_0125.jpg",
            },
        ]
    },
]

export const listCategory = [
    {
        productCategory: "MENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-mens"
            },
            {
                productBrand: "PIERRE UNO",
                link: "/collections/pierre-uno"
            },
            {
                productBrand: "Jordan Cavalli",
                link: "/collections/jordan-cavalli"
            },
            {
                productBrand: "P+1",
                link: "/collections/p+1"
            }
        ]
    },
    {
        productCategory: "WOMEN",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-women"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            }
        ]
    },
    {
        productCategory: "TEENS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-teens"
            },
            {
                productBrand: "Arrow Apple",
                link: "/collections/arrow-apple"
            },
            {   productBrand: "Arrow Apple", 
                link: "/collections/teens-arrow-apple" 
            },
            {   productBrand: "Jordan Cavalli", 
                link: "/collections/teens-jordan-cavalli" 
            },
            {   productBrand: "Pierre Uno", 
                link: "/collections/teens-pierre-uno" 
            }
        ]
    },
    {
        productCategory: "KIDS",
        productList: [
            {
                productBrand: "all products",
                link: "/collections/all-kids"
            },
            { productBrand: "Finy Girls", link: "/collections/kids-finy-girls" },
            { productBrand: "Pierre Uno", link: "/collections/kids-pierre-uno" },
        ]
    },
]

export const newJson = [
    {
        category: "MENS",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
        ]
    },
    {
        category: "WOMEN",
        productList: [
            {
                category: "all",
                slug: "all",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
            {
                category: "Jordan Cavalli",
                slug: "jordan-cavalli",
                listProduct: [
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/jc_boy/1.png",
                            "/img/jc_boy/2.png",
                            "/img/jc_boy/3.png",
                            "/img/jc_boy/4.png"
                        ],
                        productThumbnail: "/img/jc_boy/1.png"
                    },
                ]
            },
            {
                category: "Pierre Uno",
                slug: "pierre-uno",
                listProduct: [
                    {
                        productName: "Pierre Uno",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "/img/pu/pu-boxer1/bx1.png",
                            "/img/pu/pu-boxer1/bx2.png",
                            "/img/pu/pu-boxer1/bx3.png",
                            "/img/pu/pu-boxer1/bx4.png",
                            "/img/pu/pu-boxer1/bx5.png",
                            "/img/pu/pu-boxer1/bx6.png",
                        ],
                        productThumbnail: "/img/pu/pu-boxer1/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/PU/PU-BOXER2/bx1.png",
                            "../img/PU/PU-BOXER2/bx2.png",
                            "../img/PU/PU-BOXER2/bx3.png",
                            "../img/PU/PU-BOXER2/bx4.png",
                            "../img/PU/PU-BOXER2/bx5.png",
                            "../img/PU/PU-BOXER2/bx6.png",
                        ],
                        productThumbnail: "../img/PU/PU-BOXER2/bx1.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/pu/pu-b01m/img_0021.png",
                            "../img/pu/pu-b01m/img_0026.png",
                            "../img/pu/pu-b01m/img_0030.png",
                            "../img/pu/pu-b01m/img_0032.png",
                        ],
                        productThumbnail: "../img/pu/pu-b01m/img_0021.png"
                    },
                    {
                        productName: "JC Boy",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img//PU/PU-BM05M/img_0089.png",
                            "../img//PU/PU-BM05M/img_0092.png",
                            "../img//PU/PU-BM05M/img_0096.png",
                            "../img//PU/PU-BM05M/img_0097.png",
                            "../img//PU/PU-BM05M/img_0098.png",
                            "../img//PU/PU-BM05M/img_0099.png",
                        ],
                        productThumbnail: "../img//PU/PU-BM05M/img_0089.png"
                    },
                ]
            },
            {
                category: "P+1",
                slug: "p+1",
                listProduct: [
                    {
                        productName: "P+1",
                        productPrice: "Rp 45.000",
                        productSize: ["S", "M", "L", "XL"],
                        ProductColor: ["Yellow", "Green"],
                        productImageList: [
                            "../img/p+1/PO-BS01 m_001.png",
                            "../img/p+1/PO-BS01 m_002.png",
                            "../img/p+1/PO-BS01 m_003.png",
                            "../img/p+1/PO-BS01 m_006.png",
                            "../img/p+1/PO-BS01 M_007.png",
                            "../img/p+1/PO-BS01 m_008.png",
                        ],
                        productThumbnail: "../img/p+1/PO-BS01 m_001.png"
                    }
                ]
            },
        ]
    }
]