import { useState, useEffect } from "react";
import Promo from "../../../components/organs/promo";
import Breadcrumb from "../components/breadcrumb";
import ProductList from "../components/productList";
import {useParams } from 'react-router-dom';
import { DataProduct, listCategory } from "../../../components/particles/data";

function Collections() {
    const { param } = useParams();
    const [listProduct, setListProduct] = useState([]);
    const [category, setCategory] = useState([]);

    const filterData = (value) => {
        const filteredData = DataProduct.filter(item => item.slug === value);
        setListProduct(filteredData);
    };

    const filterType = () => {
      if (listProduct.length !== 0) {
        const filteredData = listCategory.filter(item => item.productCategory === listProduct[0].type);
        setCategory(filteredData);
      }
    };

    useEffect(() => {
      filterData(param);
    }, [param]);

    useEffect(() => {
    filterType();
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listProduct]);

    return (
      <div>
        <Promo/>
        <div className="py-5">
            <div className="md:hidden xs:hidden lg:block lg:px-[3rem]">
                <Breadcrumb/>
            </div>
            <h1 className="flex justify-center tracking-[0.4rem] text-[35px] text-[#1c1c1c] p-7 mb-8">{listProduct.length !== 0 && listProduct[0].type}</h1>
            <ProductList listProduct={listProduct} listCategory={category}/>
        </div>
      </div>
    );
}
  
export default Collections;
  