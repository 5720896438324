import aa from "../../../assets/logo/Arrow Apple.png";
import byd from "../../../assets/logo/BYD.png";
import dudut from "../../../assets/logo/Dudut.png"
import fg from "../../../assets/logo/Finy Girls.png";
import jc from "../../../assets/logo/Jordan Cavalli.png";
import pum from "../../../assets/logo/Pierre Uno Man.png";
import puk from "../../../assets/logo/Pu kids.png";
import rs from "../../../assets/logo/R&S.png";
import toadies from "../../../assets/logo/Toadies.png";
import wl from "../../../assets/logo/WhiteLove.png";
import styles from "../../../assets/styles/slider.module.css";

const CompanyList = () => {
    return (
        <section className="bg-white py-12">
            <div className={`${styles.slider} [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]`}>
            <div className={`${styles.slideTrack} md:justify-start [&_li]:mx-8 [&_img]:max-w-none`}>
                <div className="px-5">
                <img src={aa} alt="" className="h-10 px-2" />
                </div>
                <div className="px-5">
                <img src={byd} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={dudut} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={fg} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={jc} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={pum} alt="" className="h-10 px-2" />
                </div>
                <div className="px-5">
                <img src={puk} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={rs} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={toadies} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={wl} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={aa} alt="" className="h-10 px-2" />
                </div>
                <div className="px-5">
                <img src={byd} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={dudut} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={fg} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={jc} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={pum} alt="" className="h-10 px-2" />
                </div>
                <div className="px-5">
                <img src={puk} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={rs} alt="" className="h-8"/>
                </div>
                <div className="px-5">
                <img src={toadies} alt="" className="h-10"/>
                </div>
                <div className="px-5">
                <img src={wl} alt="" className="h-8"/>
                </div>
            </div>
            </div>
        </section>
  );
}

export default CompanyList;


