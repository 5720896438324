import React from "react";

const Badge = ({name}) => {
  return (
    <div className="text-xs inline-flex items-center leading-sm uppercase px-3 py-1 bg-white text-gray-700 border">
        {name}
    </div>
  );
};

export default Badge;
